import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { CalculatorSidebar } from '../../components/calculator-sidebar';
import { Article, HeadTitle } from '@monemprunt/design-system';
import CtaImage from '../../components/cta-image';
import { Link as LinkComponent } from 'gatsby';

export interface CalculatriceTunnelProps {
    SEO: {
        description: string;
        title: string;
    };
    embedLink: string;
    calculatorPage: {
        article: string;
        title: string;
    };
}

export default function CalculatriceTunnel({
    data: {
        page: {
            SEO,
            calculatorPage: { article, title },
            embedLink
        }
    },
    location: { pathname: path }
}: PageProps<{ page: CalculatriceTunnelProps }>) {
    const [isSidebarOpen, setSidebarOpen] = React.useState(false);

    return (
        <Layout active={`simulation-emprunt-immobilier`}>
            <Seo {...SEO} pathname={path} />
            <main className="mt-25 sm:mt-32 md:mt-20 mb-10">
                <div
                    className="hidden lg:block fixed top-72 h-60 2xl:top-80 2xl:h-80 left-0 right-0"
                    style={{ zIndex: -1 }}>
                    <div className="bg-gradient-to-t from-light-50 to-transparent h-full" />
                    <div className="bg-gradient-to-b from-light-50 to-transparent h-full" />
                </div>

                <div className={'grid grid-cols-10 xl:grid-cols-12'}>
                    {/* SideBar */}
                    <CalculatorSidebar
                        active={'tunnel'}
                        isOpen={isSidebarOpen}
                        onToggle={setSidebarOpen}
                    />

                    {/* Content */}
                    <div
                        className={`col-span-8 px-5 pt-10 flex-col gap-5 mb-32 
             ${isSidebarOpen ? `hidden` : `flex`} lg:flex
              md:pt-15
              lg:pt-20
              lg:col-span-7
              xl:col-span-9
              lg:px-15`}>
                        <HeadTitle
                            as="h1"
                            label={title}
                            breakWords={false}
                            className={`lg:text-2xl font-bold uppercase italic`}
                        />

                        <iframe
                            src={embedLink}
                            className={`w-full rounded-md h-[60vh] lg:h-[70vh] shadow-center-dark`}
                        />

                        <Article
                            content={article}
                            customLink={(props) => <LinkComponent {...props} />}
                            callToAction={() => <CtaImage />}
                        />
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export const query = graphql`
    query Tunnel {
        page: strapiPageCalculetteTunnel {
            SEO {
                description
                title
            }
            calculatorPage {
                article
                title
            }
            calculatorSidebar {
                title
                icon {
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
            embedLink
            strapiId
        }
    }
`;
